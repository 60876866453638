<template>
  <el-dialog
    v-drag
    title="查看水单"
    :visible.sync="dialogVisible"
    width="700px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    append-to-body
    :before-close="cancel"
    top="1%"
  >
    <div class="content">
      <base-tab v-if="type" :tabItems="tabItems" :active="isActived" @tabChange="tabChange"></base-tab>
      <div class="imgbox">
        <img
          v-if="!isActived"
          :src="src"
          @click="open(src)"
        />
        <img
          v-else
          :src="src"
          @click="open(src)"
        />
      </div>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">关闭</div>
    </span>
  </el-dialog>
</template>
<script>
const BaseTab = () => import('@/components/tab/BaseTab')
import config from '@/utils/config'
export default {
  components: { BaseTab },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    type: {
        type: Number,
        default: 0
    },
    billId: {
        type: String,
        default: ''
    }
  },
  data() {
    return {
      feeTypeOptions: [],
      tabItems: [
        {
          id: 0,
          name: '充值水单'
        },
        {
          id: 1,
          name: '退回水单'
        }
      ],
      isActived: 0
    }
  },
  computed: {
    src() {
      let src
      if (!this.isActived) {
          src = `/backEnd/api/${config.supplyCode}/Payment/preview?id=${this.billId}&source=2`
        } else {
          src = `/backEnd/api/${config.supplyCode}/Payment/preview?id=${this.billId}&source=3`
        }
      return src
    }
  },
  methods: {
    open(v) {
      window.open(v)
    },
    tabChange(item) {
      this.isActived = item.id
    },
    cancel() {
      this.isActived = 0
      this.$emit('cancel')
    }
  }
}
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  text-align: center;
  .imgbox {
    width: calc(100% - 200px);
    margin: 30px auto 0;
    img {
      width: 100%;
      height: 600px;
      object-fit: contain;
    }
  }
}
.footers {
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #EEEEEE;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
/deep/.el-form--label-top .el-form-item__label {
  padding: 0;
}
</style>
