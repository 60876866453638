<template>
  <div class="view-contanier">
    <div class="header">
      <div class="tab">
        <span
          v-for="(item, $index) in tabList"
          :key="item.permissson"
          v-permissson="`${item.permissson}`"
          :class="['tabItem', active === $index ? 'active' : '']"
          @click="changeType($index)"
        >
          {{ item.name }}
          ({{ item.type === 10000 ? tabTotal : tabNumer.find(a => a.type === item.type)?.qty || 0 }})
        </span>
        <!-- <span
          v-permissson="'QB'"
          class="tabItem"
          :class="{ active: active === 0 }"
          @click="changeType(0)"
        >全部</span
        >
        <span
          v-permissson="'DSH'"
          class="tabItem"
          :class="{ active: active === 1 }"
          @click="changeType(1)"
        >待审核</span
        >
        <span
          v-permissson="'YSH'"
          class="tabItem"
          :class="{ active: active === 2 }"
          @click="changeType(2)"
        >已审核</span
        >
        <span
          v-permissson="'YDZ'"
          class="tabItem"
          :class="{ active: active === 3 }"
          @click="changeType(3)"
        >已到账</span
        >
        <span
          v-permissson="'YTH'"
          class="tabItem"
          :class="{ active: active === 4 }"
          @click="changeType(4)"
        >已退回</span
        > -->
      </div>
      <div class="opt">
        <div v-if="filterList.date && isActive" class="checkDate flex_c_c">
          查询时间：{{ filterList.date[0] + "~" + filterList.date[1] }}
        </div>
        <BaseFilter
          :isActive="isActive"
          :width="466"
          @sendFilter="sendFilter"
          @reset="reset"
        >
          <div class="filter">
            <el-form ref="form" :model="filterList" label-width="70px">
              <el-form-item label="上传日期">
                <el-date-picker
                  v-model="filterList.date"
                  style="width: 320px"
                  type="daterange"
                  range-separator="至"
                  align="center"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                  unlink-panels
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="客户">
                <el-select
                  v-model="filterList.companyId"
                  style="width: 320px"
                  filterable
                  placeholder="请选择客户"
                >
                  <el-option
                    v-for="item in companyDrapdown"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="水单编号">
                <base-muti-input
                  ref="baseMutiInput"
                  v-model="filterList.id"
                  style="width: 320px"
                ></base-muti-input>
              </el-form-item>
            </el-form>
          </div>
        </BaseFilter>
      </div>
    </div>
    <div class="data">
      <BaseTable
        v-if="height !== 0"
        :key="active"
        ref="baseTable"
        :height="height"
        :tableData="{
          ...tableData,
          fileds,
        }"
        :pageIndex="pagerConfig.pageIndex"
        :pageSize="pagerConfig.pageSize"
        :loading="loading"
        @handlePageChange="handlePageChange"
        @handleSelectionChange="handleSelectionChange"
        @sortChange="sortChange"
      >
        <template slot="id" slot-scope="row">
          <img v-if="row.scope.hasNewNotice" class="hasNewNotice" src="@/assets/images/const/newNotice.png" />
          <span style="color: #2395F3;">
            {{ row.scope.id }}
            <img
              v-permissson="viewWaterBillAuth()"
              src="@/assets/images/const/viewBill.png"
              title="查看水单"
              @click="viewWaterBill(row.scope, row.scope.status === -2 ? 1 : 0)"
            />
          </span>
        </template>
        <template slot="companyId" slot-scope="row">
          <p>{{ formatterCompany(row.scope.companyId) }}
            <img
              v-permissson="editAuth()"
              src="@/assets/images/const/edit.png"
              title="处理记录"
              @click="edit(row.scope)"
            />
          </p>
        </template>
        <template slot="currentAmount" slot-scope="row">
          <p class="const">
            余额:$ {{ row.scope.currentAmount }}
          </p>
          <p class="const">
            额度:$ {{ row.scope.creditAmount }}
          </p>
        </template>
        <template slot="status" slot-scope="row">
          <span class="tag" :style="styleStatus(row.scope.status)">
            {{ row.scope.status | formatterStatus }}
          </span>
        </template>
        <template slot="bankAccountNumber" slot-scope="row">
          <p>
            <span class="label" style="width: 40px;">银行</span>
            <span class="value">{{ row.scope.bankName }}</span>
          </p>
          <p>
            <span class="label" style="width: 40px;">账号</span>
            <span class="value">{{ row.scope.bankAccountNumber }}</span>
          </p>
        </template>
        <template slot="createdOn" slot-scope="row">
          <template v-if="row.scope.createdOn">
            <p>
              <span class="label" style="width: 60px;">上传人</span>
              <span class="value">{{ row.scope.createdName }}</span>
            </p>
            <p>
              <span class="label" style="width: 60px;">上传时间</span>
              <span class="value">{{ row.scope.createdOn | formatterTime }}</span>
            </p>
          </template>
          <template v-else>
            <span class="empty"></span>
          </template>
        </template>
        <template slot="verificationOn" slot-scope="row">
          <template v-if="row.scope.verificationOn">
            <p>
              <span class="label" style="width: 60px;">审核人</span>
              <span class="value">{{ row.scope.verificationName }}</span>
            </p>
            <p>
              <span class="label" style="width: 60px;">审核时间</span>
              <span class="value">{{ row.scope.verificationOn | formatterTime }}</span>
            </p>
          </template>
          <template v-else>
            <span class="empty"></span>
          </template>
        </template>
        <template slot="receiptOn" slot-scope="row">
          <template v-if="row.scope.verificationOn">
            <p>
              <span class="label" style="width: 60px;">到账人</span>
              <span class="value">{{ row.scope.receiptName }}</span>
            </p>
            <p>
              <span class="label" style="width: 60px;">到账时间</span>
              <span class="value">{{ row.scope.receiptOn | formatterTime }}</span>
            </p>
          </template>
          <template v-else>
            <span class="empty"></span>
          </template>
        </template>
        <template slot="returnOn" slot-scope="row">
          <template v-if="row.scope.verificationOn">
            <p>
              <span class="label" style="width: 60px;">退回人</span>
              <span class="value">{{ row.scope.returnName }}</span>
            </p>
            <p>
              <span class="label" style="width: 60px;">退回时间</span>
              <span class="value">{{ row.scope.returnOn | formatterTime }}</span>
            </p>
          </template>
          <template v-else>
            <span class="empty"></span>
          </template>
        </template>
        <template slot="sourceAmount" slot-scope="row">
          <span> {{ row.scope.sourceAmount }} {{ row.scope.sourceCurrency }}</span>
        </template>
        <template slot="receivedAmount" slot-scope="row">
          <span> {{ row.scope.receivedAmount }} {{ row.scope.realCurrency }}</span>
        </template>
        <template slot="handAmount" slot-scope="row">
          <span> {{ row.scope.handAmount }} {{ row.scope.realCurrency }}</span>
        </template>
        <template slot="sourceAmount2" slot-scope="row">
          <template v-if="row.scope.status === -2">
            <span> {{ row.scope.receivedAmount }} {{ row.scope.realCurrency }}</span>
          </template>
        </template>
        <template slot="opt" slot-scope="row">
          <div class="table-opt">
            <template v-if="active === 0">
              <img
                v-if="row.scope.status === 0"
                v-permissson="'check_0'"
                style="width: 24px; height: 24px"
                src="@/assets/images/const/check.png"
                title="审核"
                @click="check(row.scope)"
              />
              <img
                v-if="row.scope.status === 1"
                v-permissson="'credited_0'"
                style="width: 24px; height: 24px"
                src="@/assets/images/const/arrive.png"
                title="到账"
                @click="arrive(row.scope)"
              />
              <!-- <img
                v-if="row.scope.status === 1"
                v-permissson="'uncheck_0'"
                style="width: 24px; height: 24px"
                src="@/assets/images/const/unCheck.png"
                title="取消审核"
                @click="unCheck(row.scope.id)"
              /> -->
              <img
                v-if="row.scope.status === 2"
                v-permissson="'back_0'"
                style="width: 24px; height: 24px"
                src="@/assets/images/const/back.png"
                title="退回"
                @click="back(row.scope)"
              />
              <img
                v-permissson="'viewLog_0'"
                style="width: 24px; height: 24px"
                src="@/assets/images/const/log.png"
                title="处理记录"
                @click="viewLog(row.scope)"
              />
            </template>
            <template v-if="active === 1">
              <img
                v-permissson="'check'"
                style="width: 24px; height: 24px"
                src="@/assets/images/const/check.png"
                title="审核"
                @click="check(row.scope)"
              />
              <img
                v-permissson="'viewLog_a'"
                style="width: 24px; height: 24px"
                src="@/assets/images/const/log.png"
                title="处理记录"
                @click="viewLog(row.scope)"
              /></template>
            <template v-if="active === 2">
              <img
                v-permissson="'credited'"
                style="width: 24px; height: 24px"
                src="@/assets/images/const/arrive.png"
                title="到账"
                @click="arrive(row.scope)"
              />
              <!-- <img
                v-permissson="'uncheck'"
                style="width: 24px; height: 24px"
                src="@/assets/images/const/unCheck.png"
                title="取消审核"
                @click="unCheck(row.scope.id)"
              /> -->
              <img
                v-permissson="'viewLog_b'"
                style="width: 24px; height: 24px"
                src="@/assets/images/const/log.png"
                title="处理记录"
                @click="viewLog(row.scope)"
              />
            </template>
            <template v-if="active === 3">
              <img
                v-permissson="'back'"
                style="width: 24px; height: 24px"
                src="@/assets/images/const/back.png"
                title="退回"
                @click="back(row.scope)"
              />
              <img
                v-permissson="'viewLog_c'"
                style="width: 24px; height: 24px"
                src="@/assets/images/const/log.png"
                title="处理记录"
                @click="viewLog(row.scope)"
              />
            </template>
            <template v-if="active === 4">
              <img
                v-permissson="'viewLog_d'"
                style="width: 24px; height: 24px"
                src="@/assets/images/const/log.png"
                title="处理记录"
                @click="viewLog(row.scope)"
              />
            </template>
          </div>
        </template>
        <template #custom-total>
          <div class="custom-total">
            <div style="display: flex;">
              <div class="select">已选 {{ selectionItem.length }} 项</div>
              <div v-if="selectionItem.length" class="total" v-html="totalHtml">
              </div>
            </div>
          </div>
        </template>
      </BaseTable>
    </div>
    <wait-check-dialog
      :dialogVisible="dialogVisible"
      :billId="editData.id"
      @ok="ok"
      @cancel="cancel"
    ></wait-check-dialog>
    <arrive-dialog
      :dialogVisible="dialogVisible2"
      :editData="editData"
      :companyList="companyList"
      @ok="ok2"
      @cancel="cancel2"
    >
    </arrive-dialog>
    <view-water-bill-dialog
      :dialogVisible="dialogVisible3"
      :type="type"
      :billId="editData.id"
      @cancel="cancel3"
    >
    </view-water-bill-dialog>
    <back-water-bill-dialog
      :dialogVisible="dialogVisible4"
      :editData="editData"
      :companyList="companyList"
      @ok="ok4"
      @cancel="cancel4"
    >
    </back-water-bill-dialog>
    <water-bill-log-drawer
      :drawer="drawer"
      :billId="editData.id"
      @close="close"
    >
    </water-bill-log-drawer>
    <AccountDialog
      :dialogFormVisible="dialogFormVisible5"
      :editData="editData"
      @ok="ok"
      @cancel="cancel"
    ></AccountDialog>
  </div>
</template>

<script>
import { permissions } from '@/utils/common'
import { getPaymentPagesList, approval } from '@/api/client'
import WaitCheckDialog from './components/WaitCheckDialog'
import WaterBillLogDrawer from './components/WaterBillLogDrawer'
import ArriveDialog from './components/ArriveDialog.vue'
import ViewWaterBillDialog from './components/ViewWaterBillDialog.vue'
import BackWaterBillDialog from './components/BackWaterBillDialog.vue'
import AccountDialog from './components/AccountDialog.vue'
import { mapGetters } from 'vuex'
const BaseMutiInput = () => import('@/components/base/BaseMutiInput.vue')
const BaseFilter = () => import('@/components/filter/BaseFilter')
const BaseTable = () => import('@/components/table/BaseTable')
import { myMixins } from '@/mixins'
export default {
  name: 'WaterBill',
  components: {
    BaseFilter,
    BaseMutiInput,
    BaseTable,
    WaitCheckDialog,
    WaterBillLogDrawer,
    ArriveDialog,
    ViewWaterBillDialog,
    BackWaterBillDialog,
    AccountDialog
  },
  filters: {
    formatterStatus(status) {
      const obj = {
        0: '待审核',
        1: '已审核',
        2: '已到账',
        '-2': '已退回',
        '-1': '已退回'
      }
      return obj[status]
    },
    formatterTime(time) {
      return time ? time.slice(0, time.length - 3) : ''
    }
  },
  mixins: [myMixins],
  data() {
    return {
      pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }]
        },
      active: 0,
      height: 0,
      filterList: {
        companyId: '',
        id: '',
        date: ''
      },
      isActive: false,
      pagerConfig: {
        pageIndex: 1,
        pageSize: 20
      },
      loading: false,
      tableData: {
        columns: [],
        total: 0,
        mutiCheck: true
      },
      editData: {},
      drawer: false,
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4: false,
      dialogFormVisible5: false,
      orderObj: {},
      companyList: [],
      selectionItem: [],
      tabList: [
        { name: '全部', permissson: 'QB', type: 10000 },
        { name: '待审核', permissson: 'DSH', type: 0 },
        { name: '已审核', permissson: 'YSH', type: 1 },
        { name: '已到账', permissson: 'YDZ', type: 2 },
        { name: '已退回', permissson: 'YTH', type: -2 }
      ],
      tabNumer: [],
      tabTotal: 0,
      tabCodeConfig: {
        QB: 0,
        DSH: 1,
        YSH: 2,
        YDZ: 3,
        YTH: 4
      },
      type: 0
    }
  },
  computed: {
    ...mapGetters(['companyDrapdown']),
    fileds() {
      let arr
      switch (this.active) {
        case 0:
          arr = [
            {
              filed: 'id',
              label: '水单编号',
              width: '200',
              fixed: 'left',
              isCustom: true
            },
            {
              filed: 'currentAmount',
              label: '余额/额度',
              width: '230',
              isCustom: true
            },
            {
              filed: 'companyId',
              label: '客户',
              width: '230',
              isCustom: true
            },
            {
              filed: 'status',
              label: '状态',
              width: '100',
              isCustom: true,
              isSort: true
            },
            {
              filed: 'bankAccountNumber',
              label: '收款银行',
              width: '228',
              isCustom: true
            },
            {
              filed: 'sourceAmount',
              label: '充值金额',
              width: '150px',
              isCustom: true
            },
            {
              filed: 'receivedAmount',
              label: '到账金额',
              width: '150px',
              isCustom: true
            },
            {
              filed: 'handAmount',
              label: '手续费',
              width: '150px',
              isCustom: true
            },
            {
              filed: 'sourceAmount2',
              label: '退回金额',
              width: '150px',
              isCustom: true
            },
            {
              filed: 'exchangeRate',
              label: '汇率',
              width: '100'
            },
            {
              filed: 'description',
              label: '描述',
              width: '150'
            },
            {
              filed: 'createdOn',
              label: '上传时间',
              width: '228',
              isCustom: true,
              isSort: true
            },
            {
              filed: 'verificationOn',
              label: '审核信息',
              width: '228',
              isCustom: true,
              isSort: true
            },
            {
              filed: 'receiptOn',
              label: '到账信息',
              width: '228',
              isCustom: true,
              isSort: true
            },
            {
              filed: 'returnOn',
              label: '退回信息',
              width: '228',
              isCustom: true,
              isSort: true
            },
            {
              filed: 'opt',
              label: '操作',
              width: '140px',
              fixed: 'right',
              isCustom: true
            }
          ]
          break
        case 1:
          arr = [
          {
              filed: 'id',
              label: '水单编号',
              width: '200',
              fixed: 'left',
              isCustom: true
            },
            {
              filed: 'companyId',
              label: '客户',
              width: '230',
              isCustom: true
            },
            {
              filed: 'bankAccountNumber',
              label: '收款银行',
              width: '228',
              isCustom: true
            },
            {
              filed: 'sourceAmount',
              label: '充值金额',
              width: '150px',
              isCustom: true
            },
            {
              filed: 'exchangeRate',
              label: '汇率',
              width: '120'
            },
            {
              filed: 'description',
              label: '描述',
              width: ''
            },
            {
              filed: 'createdOn',
              label: '上传时间',
              width: '228',
              isCustom: true,
              isSort: true
            },
            {
              filed: 'opt',
              label: '操作',
              width: '140px',
              fixed: 'right',
              isCustom: true
            }
          ]
          break
        case 2:
          arr = [
          {
              filed: 'id',
              label: '水单编号',
              width: '200',
              fixed: 'left',
              isCustom: true
            },
            {
              filed: 'companyId',
              label: '客户',
              width: '230',
              isCustom: true
            },
            {
              filed: 'bankAccountNumber',
              label: '收款银行',
              width: '228',
              isCustom: true
            },
            {
              filed: 'sourceAmount',
              label: '充值金额',
              width: '150px',
              isCustom: true
            },
            {
              filed: 'exchangeRate',
              label: '汇率',
              width: '120'
            },
            {
              filed: 'description',
              label: '描述',
              width: ''
            },
            {
              filed: 'createdOn',
              label: '上传时间',
              width: '228',
              isCustom: true,
              isSort: true
            },
            {
              filed: 'verificationOn',
              label: '审核信息',
              width: '228',
              isCustom: true,
              isSort: true
            },
            {
              filed: 'opt',
              label: '操作',
              width: '140px',
              fixed: 'right',
              isCustom: true
            }
          ]
          break
        case 3:
          arr = [
          {
              filed: 'id',
              label: '水单编号',
              width: '200',
              fixed: 'left',
              isCustom: true
            },
            {
              filed: 'companyId',
              label: '客户',
              width: '230',
              isCustom: true
            },
            {
              filed: 'bankAccountNumber',
              label: '收款银行',
              width: '228',
              isCustom: true
            },
            {
              filed: 'sourceAmount',
              label: '充值金额',
              width: '150px',
              isCustom: true
            },
            {
              filed: 'receivedAmount',
              label: '到账金额',
              width: '150px',
              isCustom: true
            },
            {
              filed: 'handAmount',
              label: '手续费',
              width: '150px',
              isCustom: true
            },
            {
              filed: 'exchangeRate',
              label: '汇率',
              width: '100'
            },
            {
              filed: 'description',
              label: '描述',
              width: '100'
            },
            {
              filed: 'createdOn',
              label: '上传时间',
              width: '228',
              isCustom: true,
              isSort: true
            },
            {
              filed: 'verificationOn',
              label: '审核信息',
              width: '228',
              isCustom: true,
              isSort: true
            },
            {
              filed: 'receiptOn',
              label: '到账信息',
              width: '228',
              isCustom: true,
              isSort: true
            },
            {
              filed: 'opt',
              label: '操作',
              width: '140px',
              fixed: 'right',
              isCustom: true
            }
          ]
          break
        case 4:
          arr = [
            {
              filed: 'id',
              label: '水单编号',
              width: '200',
              fixed: 'left',
              isCustom: true
            },
            {
              filed: 'companyId',
              label: '客户',
              width: '230',
              isCustom: true
            },
            {
              filed: 'bankAccountNumber',
              label: '收款银行',
              width: '228',
              isCustom: true
            },
            {
              filed: 'sourceAmount',
              label: '充值金额',
              width: '150px',
              isCustom: true
            },
            {
              filed: 'receivedAmount',
              label: '到账金额',
              width: '150px',
              isCustom: true
            },
            {
              filed: 'handAmount',
              label: '手续费',
              width: '150px',
              isCustom: true
            },
            {
              filed: 'sourceAmount2',
              label: '退回金额',
              width: '150px',
              isCustom: true
            },
            {
              filed: 'exchangeRate',
              label: '汇率',
              width: '100'
            },
            {
              filed: 'description',
              label: '描述',
              width: '100'
            },
            {
              filed: 'createdOn',
              label: '上传时间',
              width: '228',
              isCustom: true,
              isSort: true
            },
            {
              filed: 'verificationOn',
              label: '审核信息',
              width: '228',
              isCustom: true,
              isSort: true
            },
            {
              filed: 'receiptOn',
              label: '到账信息',
              width: '228',
              isCustom: true,
              isSort: true
            },
            {
              filed: 'returnOn',
              label: '退回信息',
              width: '228',
              isCustom: true,
              isSort: true
            },
            {
              filed: 'opt',
              label: '操作',
              width: '140px',
              fixed: 'right',
              isCustom: true
            }
          ]
          break
      }
      return arr
    },
    totalHtml() {
      const arr = []
      this.selectionItem.forEach(a => {
        const item = arr.find(n => n.currency === a.sourceCurrency)
        if (!item) {
          arr.push({
            currency: a.sourceCurrency,
            num: a.sourceAmount
          })
        } else {
          item.num += +a.sourceAmount
        }
      })
      let str = '充值金额：'
      arr.forEach(a => {
        str += `<span style="color: #fe453d;font-weight: bold;"> ${a.num.toFixed(2)}  ${a.currency}</span>`
      })
      return str
    }
  },
  watch: {
    '$store.state.connect.connect': {
      handler: function(query) {
        if (query.menuCode) {
          const json = JSON.parse(query.queryJson)
          const obj = { // 0 = PendingApproved(待审核), 1 = Approved(审核通过), 2 = Received(已到账), -2 = Return(退回), -1 = Failed(审核不通过) 转化
            0: 1,
            1: 2,
            2: 3,
            '-2': 4
          }
          this.active = obj[json.status]
          this.filterList.id = [json.id]
          setTimeout(() => {
            this.$refs.baseMutiInput.$data.valueArr = [json.id]
            this.isActive = true
          }, 0)
          const param = {
            pageIndex: 1,
            pageSize: 20
          }
          this.pagerConfig = param
          this.getList(param)
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.height = document.querySelector('.data').offsetHeight - 93
    if (!this.$store.state.connect.connect.menuCode) {
      const menuList = this.$store.getters.initRouter
      const menu = permissions(this.$route.meta.code, menuList)
      const codeList = ['QB', 'DSH', 'YSH', 'YDZ', 'YTH']
      const childrenCode = menu.children.map(a => a.code)
      this.active = codeList.findIndex((a) => childrenCode.includes(a))
      this.getList(this.pagerConfig)
    }
    if (!this.companyDrapdown.length) {
      this.$store.dispatch('getCompanyDrapdown')
    }
  },
  beforeDestroy() {
    this.$store.dispatch('setEmptyConnect')
  },
  methods: {
    formatterCompany(companyId) {
      this.companyList = this.companyDrapdown
      const item = this.companyDrapdown.find(
        (a) => a.value === companyId
      )
      return item ? item.label : ''
    },
    styleStatus(status) {
      const obj = {
        0: {
          background: 'rgba(254, 170, 79, .2)',
          color: 'rgba(254, 170, 79, 1)'
        },
        1: {
          background: 'rgba(35, 149, 243, .2)',
          color: 'rgba(35, 149, 243, 1)'
        },
        2: {
          background: 'rgba(53, 186, 131, .2)',
          color: 'rgba(53, 186, 131, 1)'
        },
        '-2': {
          background: 'rgba(254, 69, 61, .2)',
          color: 'rgba(254, 69, 61, 1)'
        },
        '-1': {
          background: 'rgba(254, 69, 61, .2)',
          color: 'rgba(254, 69, 61, 1)'
        }
      }
      return obj[status]
    },
    viewWaterBillAuth() {
      const obj = {
        0: 'viewWaterBill_0',
        1: 'viewWaterBill_a',
        2: 'viewWaterBill_b',
        3: 'viewWaterBill_c',
        4: 'viewWaterBill_d'
      }
      return obj[this.active]
    },
    editAuth() {
      const obj = {
        0: 'edit',
        1: 'edit_a',
        2: 'edit_b',
        3: 'edit_c',
        4: 'edit_d'
      }
      return obj[this.active]
    },
    changeType(type) {
      this.$store.dispatch('setEmptyConnect')
      this.active = type
      const param = {
        pageIndex: 1,
        pageSize: this.pagerConfig.pageSize
      }
      this.pagerConfig = param
      this.selectionItem = []
      this.getList(param)
    },
    async getList(params) {
      this.loading = true
      try {
        let paymentStatus
        switch (this.active) {
          // case 0:
          //   paymentStatus = []
          //   break
          case 1:
            paymentStatus = [0]
            break
          case 2:
            paymentStatus = [1]
            break
          case 3:
            paymentStatus = [2]
            break
          case 4:
            paymentStatus = [-2]
            break
        }
        params = {
          ...params,
          queryParam: {
            paymentStatus,
            ...this.orderObj,
            ...this.formatterFilter()
          }
        }
        const res = await getPaymentPagesList(params)
        this.loading = false
        if (res.success) {
          this.tableData.columns = res.data.list
          this.tableData.total = res.data.total
          this.tabNumer = res.data.tabNumer
          this.tabTotal = res.data.tabTotal
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    handlePageChange(pager) {
      this.pagerConfig = {
        ...this.pagerConfig,
        ...pager
      }
      const params = {
        ...this.pagerConfig,
        ...pager
      }
      this.getList(params)
    },
    sortChange(orderObj) {
      if (orderObj.order) {
        this.orderObj = {
          sortField: orderObj.prop,
          sortBy: orderObj.order === 'asc' ? 0 : 1
        }
      } else {
        this.orderObj = {}
      }
      this.getList(this.pagerConfig)
    },
    sendFilter() {
      if (
        this.filterList.companyId !== '' ||
        this.filterList.id !== '' ||
        this.filterList.date !== ''
      ) {
        this.isActive = true
      } else {
        this.isActive = false
      }
      const params = {
        pageIndex: 1,
        pageSize: 20
      }
      this.pagerConfig = params
      this.getList(params)
    },
    reset() {
      this.filterList = {
        companyId: '',
        id: '',
        date: ''
      }
      this.$refs.baseMutiInput.reset()
      this.isActive = false
    },
    handleSelectionChange(items) {
      this.selectionItem = items
    },
    formatterFilter() {
      return {
        companyId:
          this.filterList.companyId !== ''
            ? this.filterList.companyId
            : undefined,
        id: this.filterList.id !== '' ? this.filterList.id : undefined,
        beginDate:
          this.filterList.date !== '' ? this.filterList.date[0] : undefined,
        endDate:
          this.filterList.date !== '' ? this.filterList.date[1] : undefined
      }
    },
    check(row) {
      this.editData = row
      this.dialogVisible = true
    },
    ok() {
      const param = {
        pageIndex: 1,
        pageSize: 20
      }
      this.pagerConfig = param
      this.getList(param)
      this.cancel()
      // this.checkCode('check')
    },
    cancel() {
      this.dialogVisible = false
      this.dialogVisible1 = false
      this.dialogVisible2 = false
      this.dialogVisible3 = false
      this.dialogVisible4 = false
      this.dialogFormVisible5 = false
      this.editData = {}
    },
    viewLog(row) {
      this.editData = row
      this.drawer = true
    },
    edit(row) {
      this.editData = row
      this.dialogFormVisible5 = true
    },
    close() {
      this.drawer = false
      this.editData = {}
    },
    arrive(row) {
      this.editData = row
      this.dialogVisible2 = true
    },
    ok2() {
      const param = {
        pageIndex: 1,
        pageSize: 20
      }
      this.pagerConfig = param
      this.getList(param)
      this.cancel2()
      // this.checkCode('credited')
    },
    cancel2() {
      this.dialogVisible2 = false
      this.editData = {}
    },
    viewWaterBill(row, type) {
      this.type = type
      this.editData = row
      this.dialogVisible3 = true
    },
    cancel3() {
      this.dialogVisible3 = false
      this.editData = {}
    },
    async unCheck(id) {
      const res = await approval({ id, status: 0 })
      try {
        if (res.success) {
          this.$message.success('取消审核成功')
          this.getList(this.pagerConfig)
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.log(error)
      }
    },
    back(row) {
      this.editData = row
      this.dialogVisible4 = true
    },
    ok4() {
      const param = {
        pageIndex: 1,
        pageSize: 20
      }
      this.pagerConfig = param
      this.getList(param)
      this.cancel4()
      // this.checkCode('check')
    },
    cancel4() {
      this.dialogVisible4 = false
      this.editData = {}
    }
    // async checkCode(code) {
    //   if (
    //     this.$store.state.connect.connect.btnCode &&
    //     code === this.$store.state.connect.connect.btnCode
    //   ) {
    //     this.$store.dispatch('readConnnet')
    //   }
    // }
  }
}
</script>

<style lang="less" scoped>
.view-contanier {
  width: 100%;
  padding: 0 10px;
  height: 100%;
  background: #fff;
  box-sizing: border-box;
  overflow: auto;
  .header {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .tabItem {
      padding: 15px 20px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
      cursor: pointer;
      box-sizing: border-box;
    }
    .active {
      color: #2395f3;
    }
    .opt {
      display: flex;
    }
  }
  .hasNewNotice {
    position: absolute;
    top: 0;
    left: 0;
  }
  .icon {
    font-size: 20px;
    vertical-align: text-top;
  }
  .data {
    width: 100%;
    height: calc(100% - 50px);
    & .fTable {
      border-top: 1px solid #ebeef5;
      box-sizing: border-box;
    }
    img {
      vertical-align: text-bottom;
      cursor: pointer;
    }
    .const {
      width: auto;
      height: 20px;
      background: rgba(255, 116, 68, .1);
      color: rgba(255, 116, 68, 1);
      border-radius: 4px 4px 4px 4px;
      line-height: 20px;
      padding: 0 5px;
      font-size: 12px;
      box-sizing: border-box;
    }
    .tag {
      width: 50px;
      height: 26px;
      border-radius: 4px 4px 4px 4px;
      display: inline-block;
      line-height: 26px;
    }
    p {
      text-align: left
    }
    p + p {
      margin-top: 1px;
    }
    .label {
      width: auto;
      height: 20px;
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      background: #F7F7F7;
      line-height: 20px;
      display: inline-block;
      text-align: center;
    }
    .value {
      width: auto;
      height: 20px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
      margin-left: 5px;
    }
    .empty {
      width: 20px;
      height: 2px;
      opacity: 1;
      background: #CDCDCD;
      display: inline-block;
    }
  }
  .custom-total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
    background: #f7f7f7;
    border: 1px solid #eeeeee;
    border-top: 0;
    padding-left: 17px;
    padding-right: 40px;
    box-sizing: border-box;
    .select {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
    }
    .total {
      margin-left: 16px;
      font-size: 14px;
      font-family: Microsoft YaHei-Bold, Microsoft YaHei;
      color: #333333;
      span {
        color: #fe453d;
        font-weight: bold;
      }
    }
  }
  .circle {
    width: 6px;
    height: 6px;
    opacity: 1;
    display: inline-block;
    border-radius: 50%;
    margin-right: 6px;
  }
  .table-opt {
    display: flex;
    justify-content: space-around;
    img {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
  .status {
    width: 28px;
    height: 19px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #2395f3;
    line-height: 20px;
    margin-left: px;
  }
}
</style>
