<template>
  <el-dialog
    v-drag
    title="到账"
    :visible.sync="dialogVisible"
    width="680px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    append-to-body
    :before-close="cancel"
  >
    <el-form
      ref="form"
      :model="form"
      label-position="top"
      label-width="80px"
    >
      <el-form-item label="到账金额" prop="receivedAmount">
        <el-input-number
          v-model="form.receivedAmount"
          style="width: 320px"
          controls-position="right"
          :min="0"
          :precision="2"
          :step="0.1"
        >
        </el-input-number>
        {{ editData.realCurrency }}
      </el-form-item>
      <el-form-item label="手续费" prop="handAmount">
        <el-input-number
          v-model="form.handAmount"
          disabled
          style="width: 320px"
          controls-position="right"
          :min="0"
          :precision="2"
          :step="0.1"
        >
        </el-input-number>
        {{ editData.realCurrency }}
      </el-form-item>
      <el-form-item
        label="备注"
        prop="description"
      >
        <el-input v-model="form.description" placeholder="请输入备注内容" />
      </el-form-item>
      <el-form-item
        label="水单信息"
      >
        <div class="content">
          <div class="panel">
            <label>水单编号</label>
            <span>{{ editData.id }}</span>
          </div>
          <div class="panel">
            <label>上传时间</label>
            <span>{{ editData.createdOn ? editData.createdOn.slice(0, editData.createdOn.length - 3) : '' }}</span>
          </div>
          <div class="panel">
            <label>客&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;户</label>
            <span>{{ formatterCompany(editData.companyId) }}</span>
          </div>
          <div class="panel">
            <label>充值金额</label>
            <span>{{ editData.sourceAmount }}  {{ editData.sourceCurrency }}</span>
          </div>
          <div class="panel">
            <label>收款银行</label>
            <span>{{ editData.bankAccountNumber }}</span>
          </div>
          <div class="panel">
            <label>汇&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;率</label>
            <span>{{ editData.exchangeRate }}</span>
          </div>
          <div class="panel">
            <label>收款账号</label>
            <span>{{ editData.bankAccountNumber }}</span>
          </div>
          <div class="panel">
            <label>实际金额</label>
            <span>{{ editData.realAmount }}  {{ editData.realCurrency }}</span>
          </div>
        </div>
      </el-form-item>
    </el-form>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div v-loading="loading" class="ok flex_c_c" @click="ok('form')">确 定</div>
    </span>
  </el-dialog>
</template>
<script>
import { approval } from '@/api/client'
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    editData: {
      type: Object,
      default: function() {
        return {}
      },
      require: true
    },
    companyList: {
        type: Array,
        default: function() {
            return []
        }
    }
  },
  data() {
    return {
      form: {
        receivedAmount: '',
        handAmount: '',
        description: ''
      },
      rules: {
        receivedAmount: [{ required: true, message: '请输入到账金额', trigger: 'change' }]
      },
      loading: false
    }
  },
  watch: {
    'form.receivedAmount'(value) {
        this.form.handAmount = this.editData.realAmount - value
    },
    dialogVisible(v) {
      v && (this.form.receivedAmount = this.editData.realAmount)
    }
  },
  methods: {
    formatterCompany(companyId) {
      const item = this.companyList.find(
        (a) => a.value === companyId
      )
      return item ? item.label : ''
    },
    cancel() {
      this.$emit('cancel')
      this.$refs['form'].resetFields()
    },
    open() {
      window.open(`/backEnd/api/Payment/preview?id=${this.billId}`)
    },
    ok(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.form.receivedAmount > this.editData.realAmount) {
            this.$message.error('到账金额不能大于实际金额')
            return
          }
          this.loading = true
          approval({
            ...this.form,
            status: 2,
            id: this.editData.id
          }).then((res) => {
            if (res.success) {
              this.$message.success('操作成功')
              this.$refs[formName].resetFields()
              this.$emit('ok')
              this.loading = false
            } else {
              this.$message.error(res.errorMessage)
              this.loading = false
            }
          })
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.content {
    width: 560px;
    height: 121px;
    margin: 0 auto;
    background: #F7F7F7;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #EEEEEE;
    padding: 20px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .panel {
        width: 50%;
        height: 19px;
        line-height: 19px;
        text-indent: 20px;
    }
    label {
        width: 56px;
        height: 19px;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
    }
    span {
        width: auto;
        height: 19px;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
        margin-left: 20px;
    }
}
:deep(.el-dialog__body) {
    padding: 30px 60px;
}
.footers {
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #EEEEEE;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>
