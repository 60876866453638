<template>
  <Drawer :drawer="drawer" title="处理记录" :size="720" @close="handleClose">
    <div class="log-body">
      <BaseTable :height="height" :tableData="tableData" :isPager="false" :loading="loading">
        <template slot="index" slot-scope="row">
          {{ tableData.columns.indexOf(row.scope) + 1 }}
        </template>
      </BaseTable>
    </div>
  </Drawer>
</template>
<script>
import { getPaymentLogs } from '@/api/client'

const BaseTable = () => import('@/components/table/BaseTable.vue')
const Drawer = () => import('@/components/drawer/Drawer')
import config from '@/utils/config'
export default {
  components: { BaseTable, Drawer },
  props: {
    drawer: {
      type: Boolean,
      default: false
    },
    billId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      tableData: {
        fileds: [
          {
            filed: 'createdOn',
            label: '操作日期',
            width: '160px',
            formatter: (row) =>
              row.createdOn ? row.createdOn.slice(0, row.createdOn.length - 3) : ''
          },
          {
            filed: 'type',
            label: '操作类型',
            width: '140px',
            formatter: (row) => {
              const item = config.waterBillType.find(
                (a) => a.value === row.type
              )
              return item.label
            }
          },
          {
            filed: 'description',
            label: '描述',
            width: ''
          }
        ],
        columns: [],
        total: 0
      },
      height: 0
    }
  },
  watch: {
    async drawer(val) {
      if (val) {
        await this.getLists()
        this.$nextTick(() => {
          this.height = document.querySelector('.log-body').offsetHeight - 100
        })
      }
    }
  },
  methods: {
    getLists() {
      this.loading = true
      getPaymentLogs({
        id: this.billId
      }).then((res) => {
        if (res.success) {
          this.loading = false
          this.tableData.columns = res.data
        } else {
          this.$message.error(res.errorMessage)
        }
      })
    },
    handleClose() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.log-body {
  width: calc(100% - 40px);
  height: 100%;
  margin: 20px auto 20px;
}
</style>
