var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: "到账",
        visible: _vm.dialogVisible,
        width: "680px",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "append-to-body": "",
        "before-close": _vm.cancel,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            "label-position": "top",
            "label-width": "80px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "到账金额", prop: "receivedAmount" } },
            [
              _c("el-input-number", {
                staticStyle: { width: "320px" },
                attrs: {
                  "controls-position": "right",
                  min: 0,
                  precision: 2,
                  step: 0.1,
                },
                model: {
                  value: _vm.form.receivedAmount,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "receivedAmount", $$v)
                  },
                  expression: "form.receivedAmount",
                },
              }),
              _vm._v(" " + _vm._s(_vm.editData.realCurrency) + " "),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "手续费", prop: "handAmount" } },
            [
              _c("el-input-number", {
                staticStyle: { width: "320px" },
                attrs: {
                  disabled: "",
                  "controls-position": "right",
                  min: 0,
                  precision: 2,
                  step: 0.1,
                },
                model: {
                  value: _vm.form.handAmount,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "handAmount", $$v)
                  },
                  expression: "form.handAmount",
                },
              }),
              _vm._v(" " + _vm._s(_vm.editData.realCurrency) + " "),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "备注", prop: "description" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入备注内容" },
                model: {
                  value: _vm.form.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "description", $$v)
                  },
                  expression: "form.description",
                },
              }),
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "水单信息" } }, [
            _c("div", { staticClass: "content" }, [
              _c("div", { staticClass: "panel" }, [
                _c("label", [_vm._v("水单编号")]),
                _c("span", [_vm._v(_vm._s(_vm.editData.id))]),
              ]),
              _c("div", { staticClass: "panel" }, [
                _c("label", [_vm._v("上传时间")]),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.editData.createdOn
                        ? _vm.editData.createdOn.slice(
                            0,
                            _vm.editData.createdOn.length - 3
                          )
                        : ""
                    )
                  ),
                ]),
              ]),
              _c("div", { staticClass: "panel" }, [
                _c("label", [_vm._v("客       户")]),
                _c("span", [
                  _vm._v(_vm._s(_vm.formatterCompany(_vm.editData.companyId))),
                ]),
              ]),
              _c("div", { staticClass: "panel" }, [
                _c("label", [_vm._v("充值金额")]),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.editData.sourceAmount) +
                      " " +
                      _vm._s(_vm.editData.sourceCurrency)
                  ),
                ]),
              ]),
              _c("div", { staticClass: "panel" }, [
                _c("label", [_vm._v("收款银行")]),
                _c("span", [_vm._v(_vm._s(_vm.editData.bankAccountNumber))]),
              ]),
              _c("div", { staticClass: "panel" }, [
                _c("label", [_vm._v("汇       率")]),
                _c("span", [_vm._v(_vm._s(_vm.editData.exchangeRate))]),
              ]),
              _c("div", { staticClass: "panel" }, [
                _c("label", [_vm._v("收款账号")]),
                _c("span", [_vm._v(_vm._s(_vm.editData.bankAccountNumber))]),
              ]),
              _c("div", { staticClass: "panel" }, [
                _c("label", [_vm._v("实际金额")]),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.editData.realAmount) +
                      " " +
                      _vm._s(_vm.editData.realCurrency)
                  ),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "span",
        { staticClass: "footers", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "div",
            { staticClass: "cancel flex_c_c", on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "ok flex_c_c",
              on: {
                click: function ($event) {
                  return _vm.ok("form")
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }