<template>
  <el-dialog
    v-drag
    title="退回"
    :visible.sync="dialogVisible"
    width="680px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    append-to-body
    :before-close="cancel"
  >
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-position="top"
      label-width="80px"
    >
      <el-form-item label="退回原因" prop="description">
        <base-select-common
          ref="BaseSelectCommon"
          v-model="form.description"
          :type="16"
          title="原因"
        ></base-select-common>
      </el-form-item>
      <el-form-item label="上传水单" prop="returnsystemFileId">
        <el-upload
          class="avatar-uploader"
          :action="`/backEnd/api/${config.supplyCode}/File/uploadFile?source=3`"
          :show-file-list="false"
          :limit="1"
          :before-upload="beforeUpload"
          :on-success="handleAvatarSuccess">
          <img v-if="form.returnsystemFileId" width="80" height="80" :src="imageUrl" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item
        label="水单信息"
      >
        <div class="content">
          <div class="panel">
            <label>水单编号</label>
            <span>{{ editData.id }}</span>
          </div>
          <div class="panel">
            <label>上传时间</label>
            <span>{{ editData.createdOn }}</span>
          </div>
          <div class="panel">
            <label>客&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;户</label>
            <span>{{ formatterCompany(editData.companyId) }}</span>
          </div>
          <div class="panel">
            <label>充值金额</label>
            <span>{{ editData.sourceAmount }}  {{ editData.sourceCurrency }}</span>
          </div>
          <div class="panel">
            <label>收款银行</label>
            <span>{{ editData.bankAccountNumber }}</span>
          </div>
          <div class="panel">
            <label>汇&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;率</label>
            <span>{{ editData.exchangeRate }}</span>
          </div>
          <div class="panel">
            <label>收款账号</label>
            <span>{{ editData.bankAccountNumber }}</span>
          </div>
          <div class="panel">
            <label>退回金额</label>
            <span>{{ editData.receivedAmount }}  {{ editData.realCurrency }}</span>
          </div>
          <div class="panel">
            <label>描&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;述</label>
            <span>{{ editData.description }}</span>
          </div>
        </div>
      </el-form-item>
    </el-form>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div class="ok flex_c_c" @click="ok('form')">确 定</div>
    </span>
  </el-dialog>
</template>
<script>
import { approval } from '@/api/client'
import config from '@/utils/config'
const BaseSelectCommon = () => import('@/components/base/BaseSelectCommon.vue')
export default {
  components: { BaseSelectCommon },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    editData: {
      type: Object,
      default: function() {
        return {}
      },
      require: true
    },
    companyList: {
        type: Array,
        default: function() {
            return []
        }
    }
  },
  data() {
    return {
      form: {
        receivedAmount: '',
        handAmount: '',
        description: '',
        returnsystemFileId: ''
      },
      config,
      rules: {
        description: [{ required: true, message: '请输入退回原因', trigger: 'change' }],
        returnsystemFileId: [{ required: true, message: '请上传水单', trigger: 'change' }]
      },
      guid: ''
    }
  },
  computed: {
    imageUrl: function() {
        return `/backEnd/api/${config.supplyCode}/File/preview?guid=${this.guid}`
    }
  },
  watch: {
    'form.receivedAmount'(value) {
        this.form.handAmount = this.editData.realAmount - value
    }
  },
  methods: {
    formatterCompany(companyId) {
      const item = this.companyList.find(
        (a) => a.value === companyId
      )
      return item ? item.label : ''
    },
    cancel() {
      this.$refs['form'].resetFields()
      this.$emit('cancel')
    },
    beforeUpload(file) {
      const types = ['image/jpeg', 'image/jpg', 'image/png']
      const isImage = types.includes(file.type)
      if (!isImage) {
        this.$message.error('只能上传图片')
        return false
      }
    },
    open() {
      window.open(`/backEnd/api/${config.supplyCode}/Payment/preview?id=${this.billId}`)
    },
    ok(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let description = this.form.description
          if (description) {
            description = this.$refs.BaseSelectCommon.getValueLable(description)
          }
          approval({
            ...this.form,
            status: -2,
            id: this.editData.id
          }).then((res) => {
            if (res.success) {
              this.$message.success('操作成功')
              this.$refs[formName].resetFields()
              this.$emit('ok')
            } else {
              this.$message.error(res.errorMessage)
            }
          })
        }
      })
    },
    handleAvatarSuccess(res) {
        console.log(res)
        if (res.success) {
            this.form.returnsystemFileId = res.data.fileId
            this.guid = res.data.fileGuid
        } else {
            this.$message.error(res.errorMessage)
        }
    }
  }
}
</script>
<style lang="less" scoped>
.content {
    width: 560px;
    height: 121px;
    margin: 0 auto;
    background: #F7F7F7;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #EEEEEE;
    padding: 20px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .panel {
        width: 50%;
        height: 19px;
        line-height: 19px;
        text-indent: 20px;
    }
    label {
        width: 56px;
        height: 19px;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
    }
    span {
        width: auto;
        height: 19px;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
        margin-left: 20px;
    }
}
:deep(.el-dialog__body) {
    padding: 30px 60px;
}
.avatar-uploader {
    width: 80px;
    height: 80px;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #EEEEEE;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 80px;
      height: 80px;
      object-fit: contain;
    }
}
.avatar-uploader-icon {
    font-size: 40px;
    color: rgba(238, 238, 238, 1);
}
.footers {
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #EEEEEE;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>
