var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "view-contanier" },
    [
      _c("div", { staticClass: "header" }, [
        _c(
          "div",
          { staticClass: "tab" },
          _vm._l(_vm.tabList, function (item, $index) {
            return _c(
              "span",
              {
                directives: [
                  {
                    name: "permissson",
                    rawName: "v-permissson",
                    value: `${item.permissson}`,
                    expression: "`${item.permissson}`",
                  },
                ],
                key: item.permissson,
                class: ["tabItem", _vm.active === $index ? "active" : ""],
                on: {
                  click: function ($event) {
                    return _vm.changeType($index)
                  },
                },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(item.name) +
                    " (" +
                    _vm._s(
                      item.type === 10000
                        ? _vm.tabTotal
                        : _vm.tabNumer.find((a) => a.type === item.type)?.qty ||
                            0
                    ) +
                    ") "
                ),
              ]
            )
          }),
          0
        ),
        _c(
          "div",
          { staticClass: "opt" },
          [
            _vm.filterList.date && _vm.isActive
              ? _c("div", { staticClass: "checkDate flex_c_c" }, [
                  _vm._v(
                    " 查询时间：" +
                      _vm._s(
                        _vm.filterList.date[0] + "~" + _vm.filterList.date[1]
                      ) +
                      " "
                  ),
                ])
              : _vm._e(),
            _c(
              "BaseFilter",
              {
                attrs: { isActive: _vm.isActive, width: 466 },
                on: { sendFilter: _vm.sendFilter, reset: _vm.reset },
              },
              [
                _c(
                  "div",
                  { staticClass: "filter" },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "form",
                        attrs: { model: _vm.filterList, "label-width": "70px" },
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "上传日期" } },
                          [
                            _c("el-date-picker", {
                              staticStyle: { width: "320px" },
                              attrs: {
                                type: "daterange",
                                "range-separator": "至",
                                align: "center",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "结束日期",
                                "value-format": "yyyy-MM-dd",
                                "unlink-panels": "",
                                "picker-options": _vm.pickerOptions,
                              },
                              model: {
                                value: _vm.filterList.date,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filterList, "date", $$v)
                                },
                                expression: "filterList.date",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "客户" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "320px" },
                                attrs: {
                                  filterable: "",
                                  placeholder: "请选择客户",
                                },
                                model: {
                                  value: _vm.filterList.companyId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filterList, "companyId", $$v)
                                  },
                                  expression: "filterList.companyId",
                                },
                              },
                              _vm._l(_vm.companyDrapdown, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "水单编号" } },
                          [
                            _c("base-muti-input", {
                              ref: "baseMutiInput",
                              staticStyle: { width: "320px" },
                              model: {
                                value: _vm.filterList.id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filterList, "id", $$v)
                                },
                                expression: "filterList.id",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "data" },
        [
          _vm.height !== 0
            ? _c("BaseTable", {
                key: _vm.active,
                ref: "baseTable",
                attrs: {
                  height: _vm.height,
                  tableData: {
                    ..._vm.tableData,
                    fileds: _vm.fileds,
                  },
                  pageIndex: _vm.pagerConfig.pageIndex,
                  pageSize: _vm.pagerConfig.pageSize,
                  loading: _vm.loading,
                },
                on: {
                  handlePageChange: _vm.handlePageChange,
                  handleSelectionChange: _vm.handleSelectionChange,
                  sortChange: _vm.sortChange,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "id",
                      fn: function (row) {
                        return [
                          row.scope.hasNewNotice
                            ? _c("img", {
                                staticClass: "hasNewNotice",
                                attrs: {
                                  src: require("@/assets/images/const/newNotice.png"),
                                },
                              })
                            : _vm._e(),
                          _c("span", { staticStyle: { color: "#2395F3" } }, [
                            _vm._v(" " + _vm._s(row.scope.id) + " "),
                            _c("img", {
                              directives: [
                                {
                                  name: "permissson",
                                  rawName: "v-permissson",
                                  value: _vm.viewWaterBillAuth(),
                                  expression: "viewWaterBillAuth()",
                                },
                              ],
                              attrs: {
                                src: require("@/assets/images/const/viewBill.png"),
                                title: "查看水单",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.viewWaterBill(
                                    row.scope,
                                    row.scope.status === -2 ? 1 : 0
                                  )
                                },
                              },
                            }),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "companyId",
                      fn: function (row) {
                        return [
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.formatterCompany(row.scope.companyId)
                              ) + " "
                            ),
                            _c("img", {
                              directives: [
                                {
                                  name: "permissson",
                                  rawName: "v-permissson",
                                  value: _vm.editAuth(),
                                  expression: "editAuth()",
                                },
                              ],
                              attrs: {
                                src: require("@/assets/images/const/edit.png"),
                                title: "处理记录",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.edit(row.scope)
                                },
                              },
                            }),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "currentAmount",
                      fn: function (row) {
                        return [
                          _c("p", { staticClass: "const" }, [
                            _vm._v(
                              " 余额:$ " + _vm._s(row.scope.currentAmount) + " "
                            ),
                          ]),
                          _c("p", { staticClass: "const" }, [
                            _vm._v(
                              " 额度:$ " + _vm._s(row.scope.creditAmount) + " "
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "status",
                      fn: function (row) {
                        return [
                          _c(
                            "span",
                            {
                              staticClass: "tag",
                              style: _vm.styleStatus(row.scope.status),
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("formatterStatus")(row.scope.status)
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "bankAccountNumber",
                      fn: function (row) {
                        return [
                          _c("p", [
                            _c(
                              "span",
                              {
                                staticClass: "label",
                                staticStyle: { width: "40px" },
                              },
                              [_vm._v("银行")]
                            ),
                            _c("span", { staticClass: "value" }, [
                              _vm._v(_vm._s(row.scope.bankName)),
                            ]),
                          ]),
                          _c("p", [
                            _c(
                              "span",
                              {
                                staticClass: "label",
                                staticStyle: { width: "40px" },
                              },
                              [_vm._v("账号")]
                            ),
                            _c("span", { staticClass: "value" }, [
                              _vm._v(_vm._s(row.scope.bankAccountNumber)),
                            ]),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "createdOn",
                      fn: function (row) {
                        return [
                          row.scope.createdOn
                            ? [
                                _c("p", [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "label",
                                      staticStyle: { width: "60px" },
                                    },
                                    [_vm._v("上传人")]
                                  ),
                                  _c("span", { staticClass: "value" }, [
                                    _vm._v(_vm._s(row.scope.createdName)),
                                  ]),
                                ]),
                                _c("p", [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "label",
                                      staticStyle: { width: "60px" },
                                    },
                                    [_vm._v("上传时间")]
                                  ),
                                  _c("span", { staticClass: "value" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatterTime")(
                                          row.scope.createdOn
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            : [_c("span", { staticClass: "empty" })],
                        ]
                      },
                    },
                    {
                      key: "verificationOn",
                      fn: function (row) {
                        return [
                          row.scope.verificationOn
                            ? [
                                _c("p", [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "label",
                                      staticStyle: { width: "60px" },
                                    },
                                    [_vm._v("审核人")]
                                  ),
                                  _c("span", { staticClass: "value" }, [
                                    _vm._v(_vm._s(row.scope.verificationName)),
                                  ]),
                                ]),
                                _c("p", [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "label",
                                      staticStyle: { width: "60px" },
                                    },
                                    [_vm._v("审核时间")]
                                  ),
                                  _c("span", { staticClass: "value" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatterTime")(
                                          row.scope.verificationOn
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            : [_c("span", { staticClass: "empty" })],
                        ]
                      },
                    },
                    {
                      key: "receiptOn",
                      fn: function (row) {
                        return [
                          row.scope.verificationOn
                            ? [
                                _c("p", [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "label",
                                      staticStyle: { width: "60px" },
                                    },
                                    [_vm._v("到账人")]
                                  ),
                                  _c("span", { staticClass: "value" }, [
                                    _vm._v(_vm._s(row.scope.receiptName)),
                                  ]),
                                ]),
                                _c("p", [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "label",
                                      staticStyle: { width: "60px" },
                                    },
                                    [_vm._v("到账时间")]
                                  ),
                                  _c("span", { staticClass: "value" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatterTime")(
                                          row.scope.receiptOn
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            : [_c("span", { staticClass: "empty" })],
                        ]
                      },
                    },
                    {
                      key: "returnOn",
                      fn: function (row) {
                        return [
                          row.scope.verificationOn
                            ? [
                                _c("p", [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "label",
                                      staticStyle: { width: "60px" },
                                    },
                                    [_vm._v("退回人")]
                                  ),
                                  _c("span", { staticClass: "value" }, [
                                    _vm._v(_vm._s(row.scope.returnName)),
                                  ]),
                                ]),
                                _c("p", [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "label",
                                      staticStyle: { width: "60px" },
                                    },
                                    [_vm._v("退回时间")]
                                  ),
                                  _c("span", { staticClass: "value" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatterTime")(
                                          row.scope.returnOn
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            : [_c("span", { staticClass: "empty" })],
                        ]
                      },
                    },
                    {
                      key: "sourceAmount",
                      fn: function (row) {
                        return [
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(row.scope.sourceAmount) +
                                " " +
                                _vm._s(row.scope.sourceCurrency)
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "receivedAmount",
                      fn: function (row) {
                        return [
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(row.scope.receivedAmount) +
                                " " +
                                _vm._s(row.scope.realCurrency)
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "handAmount",
                      fn: function (row) {
                        return [
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(row.scope.handAmount) +
                                " " +
                                _vm._s(row.scope.realCurrency)
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "sourceAmount2",
                      fn: function (row) {
                        return [
                          row.scope.status === -2
                            ? [
                                _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(row.scope.receivedAmount) +
                                      " " +
                                      _vm._s(row.scope.realCurrency)
                                  ),
                                ]),
                              ]
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "opt",
                      fn: function (row) {
                        return [
                          _c(
                            "div",
                            { staticClass: "table-opt" },
                            [
                              _vm.active === 0
                                ? [
                                    row.scope.status === 0
                                      ? _c("img", {
                                          directives: [
                                            {
                                              name: "permissson",
                                              rawName: "v-permissson",
                                              value: "check_0",
                                              expression: "'check_0'",
                                            },
                                          ],
                                          staticStyle: {
                                            width: "24px",
                                            height: "24px",
                                          },
                                          attrs: {
                                            src: require("@/assets/images/const/check.png"),
                                            title: "审核",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.check(row.scope)
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                    row.scope.status === 1
                                      ? _c("img", {
                                          directives: [
                                            {
                                              name: "permissson",
                                              rawName: "v-permissson",
                                              value: "credited_0",
                                              expression: "'credited_0'",
                                            },
                                          ],
                                          staticStyle: {
                                            width: "24px",
                                            height: "24px",
                                          },
                                          attrs: {
                                            src: require("@/assets/images/const/arrive.png"),
                                            title: "到账",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.arrive(row.scope)
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                    row.scope.status === 2
                                      ? _c("img", {
                                          directives: [
                                            {
                                              name: "permissson",
                                              rawName: "v-permissson",
                                              value: "back_0",
                                              expression: "'back_0'",
                                            },
                                          ],
                                          staticStyle: {
                                            width: "24px",
                                            height: "24px",
                                          },
                                          attrs: {
                                            src: require("@/assets/images/const/back.png"),
                                            title: "退回",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.back(row.scope)
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                    _c("img", {
                                      directives: [
                                        {
                                          name: "permissson",
                                          rawName: "v-permissson",
                                          value: "viewLog_0",
                                          expression: "'viewLog_0'",
                                        },
                                      ],
                                      staticStyle: {
                                        width: "24px",
                                        height: "24px",
                                      },
                                      attrs: {
                                        src: require("@/assets/images/const/log.png"),
                                        title: "处理记录",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.viewLog(row.scope)
                                        },
                                      },
                                    }),
                                  ]
                                : _vm._e(),
                              _vm.active === 1
                                ? [
                                    _c("img", {
                                      directives: [
                                        {
                                          name: "permissson",
                                          rawName: "v-permissson",
                                          value: "check",
                                          expression: "'check'",
                                        },
                                      ],
                                      staticStyle: {
                                        width: "24px",
                                        height: "24px",
                                      },
                                      attrs: {
                                        src: require("@/assets/images/const/check.png"),
                                        title: "审核",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.check(row.scope)
                                        },
                                      },
                                    }),
                                    _c("img", {
                                      directives: [
                                        {
                                          name: "permissson",
                                          rawName: "v-permissson",
                                          value: "viewLog_a",
                                          expression: "'viewLog_a'",
                                        },
                                      ],
                                      staticStyle: {
                                        width: "24px",
                                        height: "24px",
                                      },
                                      attrs: {
                                        src: require("@/assets/images/const/log.png"),
                                        title: "处理记录",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.viewLog(row.scope)
                                        },
                                      },
                                    }),
                                  ]
                                : _vm._e(),
                              _vm.active === 2
                                ? [
                                    _c("img", {
                                      directives: [
                                        {
                                          name: "permissson",
                                          rawName: "v-permissson",
                                          value: "credited",
                                          expression: "'credited'",
                                        },
                                      ],
                                      staticStyle: {
                                        width: "24px",
                                        height: "24px",
                                      },
                                      attrs: {
                                        src: require("@/assets/images/const/arrive.png"),
                                        title: "到账",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.arrive(row.scope)
                                        },
                                      },
                                    }),
                                    _c("img", {
                                      directives: [
                                        {
                                          name: "permissson",
                                          rawName: "v-permissson",
                                          value: "viewLog_b",
                                          expression: "'viewLog_b'",
                                        },
                                      ],
                                      staticStyle: {
                                        width: "24px",
                                        height: "24px",
                                      },
                                      attrs: {
                                        src: require("@/assets/images/const/log.png"),
                                        title: "处理记录",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.viewLog(row.scope)
                                        },
                                      },
                                    }),
                                  ]
                                : _vm._e(),
                              _vm.active === 3
                                ? [
                                    _c("img", {
                                      directives: [
                                        {
                                          name: "permissson",
                                          rawName: "v-permissson",
                                          value: "back",
                                          expression: "'back'",
                                        },
                                      ],
                                      staticStyle: {
                                        width: "24px",
                                        height: "24px",
                                      },
                                      attrs: {
                                        src: require("@/assets/images/const/back.png"),
                                        title: "退回",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.back(row.scope)
                                        },
                                      },
                                    }),
                                    _c("img", {
                                      directives: [
                                        {
                                          name: "permissson",
                                          rawName: "v-permissson",
                                          value: "viewLog_c",
                                          expression: "'viewLog_c'",
                                        },
                                      ],
                                      staticStyle: {
                                        width: "24px",
                                        height: "24px",
                                      },
                                      attrs: {
                                        src: require("@/assets/images/const/log.png"),
                                        title: "处理记录",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.viewLog(row.scope)
                                        },
                                      },
                                    }),
                                  ]
                                : _vm._e(),
                              _vm.active === 4
                                ? [
                                    _c("img", {
                                      directives: [
                                        {
                                          name: "permissson",
                                          rawName: "v-permissson",
                                          value: "viewLog_d",
                                          expression: "'viewLog_d'",
                                        },
                                      ],
                                      staticStyle: {
                                        width: "24px",
                                        height: "24px",
                                      },
                                      attrs: {
                                        src: require("@/assets/images/const/log.png"),
                                        title: "处理记录",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.viewLog(row.scope)
                                        },
                                      },
                                    }),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      },
                    },
                    {
                      key: "custom-total",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "custom-total" }, [
                            _c("div", { staticStyle: { display: "flex" } }, [
                              _c("div", { staticClass: "select" }, [
                                _vm._v(
                                  "已选 " +
                                    _vm._s(_vm.selectionItem.length) +
                                    " 项"
                                ),
                              ]),
                              _vm.selectionItem.length
                                ? _c("div", {
                                    staticClass: "total",
                                    domProps: {
                                      innerHTML: _vm._s(_vm.totalHtml),
                                    },
                                  })
                                : _vm._e(),
                            ]),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  1893918111
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c("wait-check-dialog", {
        attrs: { dialogVisible: _vm.dialogVisible, billId: _vm.editData.id },
        on: { ok: _vm.ok, cancel: _vm.cancel },
      }),
      _c("arrive-dialog", {
        attrs: {
          dialogVisible: _vm.dialogVisible2,
          editData: _vm.editData,
          companyList: _vm.companyList,
        },
        on: { ok: _vm.ok2, cancel: _vm.cancel2 },
      }),
      _c("view-water-bill-dialog", {
        attrs: {
          dialogVisible: _vm.dialogVisible3,
          type: _vm.type,
          billId: _vm.editData.id,
        },
        on: { cancel: _vm.cancel3 },
      }),
      _c("back-water-bill-dialog", {
        attrs: {
          dialogVisible: _vm.dialogVisible4,
          editData: _vm.editData,
          companyList: _vm.companyList,
        },
        on: { ok: _vm.ok4, cancel: _vm.cancel4 },
      }),
      _c("water-bill-log-drawer", {
        attrs: { drawer: _vm.drawer, billId: _vm.editData.id },
        on: { close: _vm.close },
      }),
      _c("AccountDialog", {
        attrs: {
          dialogFormVisible: _vm.dialogFormVisible5,
          editData: _vm.editData,
        },
        on: { ok: _vm.ok, cancel: _vm.cancel },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }