var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: "退回",
        visible: _vm.dialogVisible,
        width: "680px",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "append-to-body": "",
        "before-close": _vm.cancel,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "label-position": "top",
            "label-width": "80px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "退回原因", prop: "description" } },
            [
              _c("base-select-common", {
                ref: "BaseSelectCommon",
                attrs: { type: 16, title: "原因" },
                model: {
                  value: _vm.form.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "description", $$v)
                  },
                  expression: "form.description",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "上传水单", prop: "returnsystemFileId" } },
            [
              _c(
                "el-upload",
                {
                  staticClass: "avatar-uploader",
                  attrs: {
                    action: `/backEnd/api/${_vm.config.supplyCode}/File/uploadFile?source=3`,
                    "show-file-list": false,
                    limit: 1,
                    "before-upload": _vm.beforeUpload,
                    "on-success": _vm.handleAvatarSuccess,
                  },
                },
                [
                  _vm.form.returnsystemFileId
                    ? _c("img", {
                        staticClass: "avatar",
                        attrs: { width: "80", height: "80", src: _vm.imageUrl },
                      })
                    : _c("i", {
                        staticClass: "el-icon-plus avatar-uploader-icon",
                      }),
                ]
              ),
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "水单信息" } }, [
            _c("div", { staticClass: "content" }, [
              _c("div", { staticClass: "panel" }, [
                _c("label", [_vm._v("水单编号")]),
                _c("span", [_vm._v(_vm._s(_vm.editData.id))]),
              ]),
              _c("div", { staticClass: "panel" }, [
                _c("label", [_vm._v("上传时间")]),
                _c("span", [_vm._v(_vm._s(_vm.editData.createdOn))]),
              ]),
              _c("div", { staticClass: "panel" }, [
                _c("label", [_vm._v("客       户")]),
                _c("span", [
                  _vm._v(_vm._s(_vm.formatterCompany(_vm.editData.companyId))),
                ]),
              ]),
              _c("div", { staticClass: "panel" }, [
                _c("label", [_vm._v("充值金额")]),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.editData.sourceAmount) +
                      " " +
                      _vm._s(_vm.editData.sourceCurrency)
                  ),
                ]),
              ]),
              _c("div", { staticClass: "panel" }, [
                _c("label", [_vm._v("收款银行")]),
                _c("span", [_vm._v(_vm._s(_vm.editData.bankAccountNumber))]),
              ]),
              _c("div", { staticClass: "panel" }, [
                _c("label", [_vm._v("汇       率")]),
                _c("span", [_vm._v(_vm._s(_vm.editData.exchangeRate))]),
              ]),
              _c("div", { staticClass: "panel" }, [
                _c("label", [_vm._v("收款账号")]),
                _c("span", [_vm._v(_vm._s(_vm.editData.bankAccountNumber))]),
              ]),
              _c("div", { staticClass: "panel" }, [
                _c("label", [_vm._v("退回金额")]),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.editData.receivedAmount) +
                      " " +
                      _vm._s(_vm.editData.realCurrency)
                  ),
                ]),
              ]),
              _c("div", { staticClass: "panel" }, [
                _c("label", [_vm._v("描       述")]),
                _c("span", [_vm._v(_vm._s(_vm.editData.description))]),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "span",
        { staticClass: "footers", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "div",
            { staticClass: "cancel flex_c_c", on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "div",
            {
              staticClass: "ok flex_c_c",
              on: {
                click: function ($event) {
                  return _vm.ok("form")
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }