var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: "审核",
        visible: _vm.dialogVisible,
        width: "600px",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "append-to-body": "",
        "before-close": _vm.cancel,
        top: "3%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "label-position": "top",
            "label-width": "80px",
          },
        },
        [
          _c("el-form-item", { attrs: { label: "水单图片" } }, [
            _c("img", {
              staticClass: "imgbox",
              attrs: {
                src: `/backEnd/api/${_vm.config.supplyCode}/Payment/preview?id=${_vm.billId}`,
              },
              on: { click: _vm.open },
            }),
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "是否通过", prop: "status" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("通过")]),
                  _c("el-radio", { attrs: { label: -1 } }, [_vm._v("不通过")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.form.status === -1
            ? _c(
                "el-form-item",
                { attrs: { label: "不通过原因", prop: "description" } },
                [
                  _c("base-select-common", {
                    ref: "baseSelectCommon",
                    attrs: { type: 16, title: "原因" },
                    model: {
                      value: _vm.form.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "description", $$v)
                      },
                      expression: "form.description",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "是否发送邮件通知", prop: "isSendEmail" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.isSendEmail,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "isSendEmail", $$v)
                    },
                    expression: "form.isSendEmail",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: true } }, [_vm._v("是")]),
                  _c("el-radio", { attrs: { label: false } }, [_vm._v("否")]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { staticClass: "footers", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "div",
            { staticClass: "cancel flex_c_c", on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loding,
                  expression: "loding",
                },
              ],
              staticClass: "ok flex_c_c",
              on: {
                click: function ($event) {
                  return _vm.ok("form")
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }