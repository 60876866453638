var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: "查看水单",
        visible: _vm.dialogVisible,
        width: "700px",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "append-to-body": "",
        "before-close": _vm.cancel,
        top: "1%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _vm.type
            ? _c("base-tab", {
                attrs: { tabItems: _vm.tabItems, active: _vm.isActived },
                on: { tabChange: _vm.tabChange },
              })
            : _vm._e(),
          _c("div", { staticClass: "imgbox" }, [
            !_vm.isActived
              ? _c("img", {
                  attrs: { src: _vm.src },
                  on: {
                    click: function ($event) {
                      return _vm.open(_vm.src)
                    },
                  },
                })
              : _c("img", {
                  attrs: { src: _vm.src },
                  on: {
                    click: function ($event) {
                      return _vm.open(_vm.src)
                    },
                  },
                }),
          ]),
        ],
        1
      ),
      _c(
        "span",
        { staticClass: "footers", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "div",
            { staticClass: "cancel flex_c_c", on: { click: _vm.cancel } },
            [_vm._v("关闭")]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }