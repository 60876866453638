<template>
  <el-dialog
    v-drag
    title="审核"
    :visible.sync="dialogVisible"
    width="600px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    append-to-body
    :before-close="cancel"
    top="3%"
  >
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-position="top"
      label-width="80px"
    >
      <el-form-item label="水单图片">
        <img
          class="imgbox"
          :src="`/backEnd/api/${config.supplyCode}/Payment/preview?id=${billId}`"
          @click="open"
        />
      </el-form-item>
      <el-form-item label="是否通过" prop="status">
        <el-radio-group v-model="form.status">
          <el-radio :label="1">通过</el-radio>
          <el-radio :label="-1">不通过</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-if="form.status === -1"
        label="不通过原因"
        prop="description"
      >
        <base-select-common
          ref="baseSelectCommon"
          v-model="form.description"
          :type="16"
          title="原因"
        ></base-select-common>
      </el-form-item>
      <el-form-item label="是否发送邮件通知" prop="isSendEmail">
        <el-radio-group v-model="form.isSendEmail">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div v-loading="loding" class="ok flex_c_c" @click="ok('form')">确 定</div>
    </span>
  </el-dialog>
</template>
<script>
import { approval } from '@/api/client'
import config from '@/utils/config'
const BaseSelectCommon = () => import('@/components/base/BaseSelectCommon.vue')
export default {
  components: { BaseSelectCommon },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    billId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      config,
      form: {
        status: 1,
        description: '',
        isSendEmail: true
      },
      rules: {
        status: [{ required: true }],
        isSendEmail: [{ required: true }],
        description: [
          { required: true, message: '请输入不通过原因', trigger: 'change' }
        ]
      },
      loding: false
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    open() {
      window.open(`/backEnd/api/${config.supplyCode}/Payment/preview?id=${this.billId}`)
    },
    ok(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loding = true
          let description = this.form.description
          if (description) {
            description = this.$refs.baseSelectCommon.getValueLable(description)
          }
          approval({
            ...this.form,
            description,
            id: this.billId
          }).then((res) => {
            this.loding = false
            if (res.success) {
              this.$message.success('操作成功')
              this.$emit('ok')
            } else {
              this.$message.error(res.errorMessage)
            }
          })
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.imgbox {
  width: 240px;
  height: 310px;
  object-fit: contain;
}
.footers {
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #EEEEEE;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>
